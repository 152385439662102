module.exports = {
    backendUrl: "https://super.server.devagil.com/super-admin-backend/",
    LAMBDA_SUPER: 'https://lambda.devagil.com/dev-super',
    LAMBDA_COMMON: 'https://lambda.devagil.com/dev-common',
    LAMBDA_AUTH: 'https://lambda.devagil.com/dev-auth',
    LAMBDA_POS: 'https://lambda.devagil.com/dev-pos',
    LAMBDA_ADMIN: 'https://lambda.devagil.com/dev-admin',
    LAMBDA_RIDER: "https://lambda.devagil.com/dev-rider",
    LAMBDA_SHOP: "https://lambda.devagil.com/dev-shop",
    LAMBDA_AGIL: "https://lambda.devagil.com/dev-agil",
    AppKey: 'bb4d49b255f3894a9bde271ffc0f20fb',
    backendUrlGraphql: "https://super.server.devagil.com/super-admin-backend/graphql",
    socketUrl: "https://socket.server.devagil.com/",
    AUTH_URL: "https://account.devagil.com",
    SOCKET_SHOP: "https://rocket.server.devagil.com/",
    MAPBOX_TOKEN: 'pk.eyJ1IjoiYWdpbG1hcCIsImEiOiJjbG1rbzY2amkwM3NoMmttb3dtMHRteTRkIn0.6gAtqofNy3MsffYN6ml4SQ',
    API_KEY_GOOGLE_MAPS: "AIzaSyCETkB78xH2peosxbPxCW7fiHxg0jxAXoM",
    RAPPI_CLIENT_ID: "t06V7QRExTn7hAWDRKYTIEACHZYRuMri",
    RAPPI_CLIENT_SECRET: "GJbjaXaLfs2HXXrtnGA1UQZMYRx6qNFKA-sXJn5qTZ1YOpIEb9X96QXAvEu5lQz9",
    RAPPI_AUDIENCE: "https://int-public-api-v2/api",
    UBER_CLIENT_ID: "UIzLa7h_zJZDo5TzzD0228KVwrmhAe0k",
    UBER_CLIENT_SECRET: "peMME0XzaSeSwE0UnjOCetqJxpJIe7hTUp9P5GoB",
    TINTY_MCE: "ucsp6j9tja5vmddqmqpsxrwd06j8i1nqh5qa24h1f4f1wmft"
}
